import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
} from "react-admin";
import { ToolbarWithoutDelete } from "../ToolbarWithoutDelete";
// import { parse } from "../../dateHelper";

// -- snip --

export const ProductEdit: React.FC<{}> = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<ToolbarWithoutDelete />}>
      <TextInput disabled source="id" style={{ width: "500px" }} />
      <TextInput
        source="name"
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <TextInput
        source="description"
        options={{ label: "Beschreibung" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="url"
        type="url"
        validate={[required()]}
        options={{ label: "Webshop URL *" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="notes"
        options={{ label: "Notizen" }}
        multiline
        style={{ width: "500px" }}
      />
      <BooleanInput
        source="isSubscription"
        label="Nach Abo-Ablauf nicht mehr verfügbar?"
        options={{ label: "Nach Abo-Ablauf nicht mehr verfügbar?" }}
        multiline
        style={{ width: "500px" }}
      />
    </SimpleForm>
  </Edit>
);
