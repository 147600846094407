import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField
} from "react-admin";

export const SubscriptionList: React.FC<{}> = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="Kategorie" source="productId" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Vorname"
        source="userId"
        reference="users"
        sortBy="user.firstname"
      >
        <TextField source="firstname" />
      </ReferenceField>
      <ReferenceField
        label="Nachname"
        source="userId"
        reference="users"
        sortBy="user.lastname"
      >
        <TextField source="lastname" />
      </ReferenceField>
      <ReferenceField
        label="EMail"
        source="userId"
        reference="users"
        sortBy="user.email"
      >
        <TextField source="email" />
      </ReferenceField>
      <DateField source="startDate" locale="de-CH" label="Start-Datum" />
      <DateField source="endDate" locale="de-CH" label="Bis-Datum" />
    </Datagrid>
  </List>
);
