import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import { ProductBulkActionButtons } from "../BulkActionButtons";

export const ProductList: React.FC<{}> = props => (
  <List
    perPage={25}
    {...props}
    bulkActionButtons={<ProductBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" label="Webshop URL" />
      <TextField source="notes" label="Notizen" />
    </Datagrid>
  </List>
);
