import { fetchUtils } from "react-admin";

type LoginInfo = {
  username: string;
  password: string;
};

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const authProvider = {
  login: ({ username, password }: LoginInfo) => {
    const request = new Request(
      process.env.REACT_APP_BACKEND_URL + "/auth/login",
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" })
      }
    );
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        localStorage.setItem("token", access_token);
      });
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem("token");
    if (token) {
      // Check if it's a valid token
      return httpClient(process.env.REACT_APP_BACKEND_URL + "/users");
    }

    // False auth
    return Promise.reject();
  },
  checkError: (error: any) => {
    console.log("Error in Checkerror " + JSON.stringify(error));
    /*
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    */

    return Promise.resolve(error);
  },
  getPermissions: (params: any) => {
    return Promise.resolve({});
  }
  // ...
};

export default authProvider;
