import React from "react";
import {
  Create,
  DateInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  TextInput,
  required,
} from "react-admin";

// -- snip --

export const SubscriptionCreate: React.FC<{}> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Benutzer Suche"
        source="userId"
        reference="users"
        validate={[required()]}
        style={{ width: "500px" }}
        sort={{ field: "email", order: "ASC" }}
        perPage={100}
        filterToQuery={(search: string) => ({ email: search ? search : " " })}
      >
        <AutocompleteInput optionText="email" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Kategorie *"
        source="productId"
        reference="products"
        validate={[required()]}
        style={{ width: "500px" }}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <DateInput
        source="startDate"
        locales="de-CH"
        options={{ label: "Start-Datum" }}
        style={{ width: "500px" }}
      />

      <DateInput
        source="endDate"
        locales="de-CH"
        options={{ label: "Bis-Datum" }}
        style={{ width: "500px" }}
      />

      <TextInput
        source="notes"
        options={{ label: "Notizen" }}
        style={{ width: "500px" }}
      />
    </SimpleForm>
  </Create>
);
