import React, { Component } from "react";
import { Admin, Resource } from "react-admin";

import crudProvider from "@fusionworks/ra-data-nest-crud";
import { MonetizationOn, Person, YouTube } from "@material-ui/icons";
import polyglotI18nProvider from "ra-i18n-polyglot";
import germanMessages from "ra-language-german";

import { UserList } from "./components/user/UserList";
import { UserEdit } from "./components/user/UserEdit";

import { authProvider, httpClient } from "./containers/authProvider";
import { VideoEdit } from "./components/video/VideoEdit";
import { VideoList } from "./components/video/VideoList";
import { VideoCreate } from "./components/video/VideoCreate";
import { ProductList } from "./components/product/ProductList";
import { ProductCreate } from "./components/product/ProductCreate";
import { ProductEdit } from "./components/product/ProductEdit";
import { SubscriptionCreate } from "./components/subscription/SubscriptionCreate";
import { SubscriptionEdit } from "./components/subscription/SubscriptionEdit";
import { SubscriptionList } from "./components/subscription/SubscriptionList";
import { UserCreate } from "./components/user/UserCreate";

const dataProvider = crudProvider(
  process.env.REACT_APP_BACKEND_URL,
  httpClient
);

const i18nProvider = polyglotI18nProvider(() => germanMessages, "de");

class App extends Component {
  render() {
    return (
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
      >
        <Resource
          name="videos"
          options={{ label: "Video" }}
          list={VideoList}
          create={VideoCreate}
          edit={VideoEdit}
          icon={YouTube}
        />

        <Resource
          name="users"
          options={{
            label: "Benutzer"
          }}
          list={UserList}
          edit={UserEdit}
          create={UserCreate}
          icon={Person}
        />

        <Resource
          name="subscriptions"
          options={{
            label: "Abo/Verkäufe"
          }}
          list={SubscriptionList}
          create={SubscriptionCreate}
          edit={SubscriptionEdit}
          icon={MonetizationOn}
        />

        <Resource
          name="products"
          options={{
            label: "Kategorie"
          }}
          list={ProductList}
          create={ProductCreate}
          edit={ProductEdit}
        />
      </Admin>
    );
  }
}

export default App;
