import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required
} from "react-admin";
// import { parse } from "../../dateHelper";

// -- snip --

export const VideoEdit: React.FC<{}> = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" style={{ width: "500px" }} />
      <TextInput
        source="name"
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <TextInput
        source="description"
        options={{ label: "Beschreibung" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="videoUrl"
        type="url"
        options={{ label: "Video Link *" }}
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <DateInput
        source="date"
        locales="de-CH"
        options={{ label: "Datum *" }}
        validate={[required()]}
        style={{ width: "500px" }}
      />

      <ReferenceInput
        label="Kategorie *"
        source="productId"
        reference="products"
        validate={[required()]}
        options={{ label: "Kategorie *" }}
        style={{ width: "500px" }}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

/*
class Video {
  @IsNumber()
  @IsOptional()
  readonly id: number;

  @IsString()
  readonly name: string;

  @IsString()
  @IsOptional()
  readonly description: string;

  @IsDateString()
  readonly date: string;
}
*/
