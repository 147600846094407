import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
} from "react-admin";

// -- snip --

export const ProductCreate: React.FC<{}> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <TextInput
        source="description"
        options={{ label: "Beschreibung" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="url"
        validate={[required()]}
        options={{ label: "Webshop URL *" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="notes"
        options={{ label: "Notizen" }}
        multiline
        style={{ width: "500px" }}
      />
      <BooleanInput
        source="isSubscription"
        label="Nach Abo-Ablauf nicht mehr verfügbar?"
        options={{ label: "Nach Abo-Ablauf nicht mehr verfügbar?" }}
        multiline
        style={{ width: "500px" }}
      />
    </SimpleForm>
  </Create>
);
