import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required
} from "react-admin";

// -- snip --

export const VideoCreate: React.FC<{}> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <TextInput
        source="description"
        multiline
        options={{ label: "Beschreibung" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="videoUrl"
        type="url"
        options={{ label: "Video Link *" }}
        validate={[required()]}
        style={{ width: "500px" }}
      />
      <DateInput
        source="date"
        locales="de-CH"
        options={{ label: "Datum *" }}
        validate={[required()]}
        style={{ width: "500px" }}
      />

      <ReferenceInput
        label="Kategorie *"
        source="productId"
        reference="products"
        validate={[required()]}
        style={{ width: "500px" }}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
