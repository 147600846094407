import React from "react";
import {
  DateInput,
  ReferenceInput,
  SelectInput,
  Edit,
  SimpleForm,
  TextInput,
  required
} from "react-admin";
// import { parse } from "../../dateHelper";

// -- snip --

export const SubscriptionEdit: React.FC<{}> = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Benutzer *"
        source="userId"
        reference="users"
        validate={[required()]}
        style={{ width: "500px" }}
      >
        <SelectInput optionText="email" optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        label="Kategorie *"
        source="productId"
        reference="products"
        validate={[required()]}
        style={{ width: "500px" }}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>

      <DateInput
        source="startDate"
        locales="de-CH"
        options={{ label: "Start-Datum" }}
        style={{ width: "500px" }}
      />

      <DateInput
        source="endDate"
        locales="de-CH"
        options={{ label: "Bis-Datum" }}
        style={{ width: "500px" }}
      />

      <TextInput
        source="notes"
        options={{ label: "Notizen" }}
        style={{ width: "500px" }}
      />
    </SimpleForm>
  </Edit>
);
