import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField
} from "react-admin";

export const VideoList: React.FC<{}> = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" label="Beschreibung" />
      <TextField source="videoUrl" label="Video Link" />
      <DateField source="date" locales="ch-DE" label="Datum" />
      <ReferenceField label="Kategorie" source="productId" reference="products">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
