import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength
} from "react-admin";

// -- snip --

export const UserCreate: React.FC<{}> = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="firstname"
        options={{ label: "Vorname" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="lastname"
        options={{ label: "Nachname" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="email"
        type="email"
        validate={[required()]}
        options={{ label: "E-Mail *" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="mobile"
        options={{ label: "Mobiltelefon" }}
        style={{ width: "500px" }}
      />
      <TextInput
        source="password"
        options={{ label: "Passwort *" }}
        validate={[required(), maxLength(20)]}
        style={{ width: "500px" }}
      />

      <TextInput
        source="notes"
        options={{ label: "Notizen" }}
        multiline
        style={{ width: "500px" }}
      />
    </SimpleForm>
  </Create>
);
