import React from "react";
import { List, Datagrid, TextField, EmailField } from "react-admin";

export const UserList: React.FC<{}> = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstname" label="Vorname" />
      <TextField source="lastname" label="Nachname" />
      <EmailField source="email" label="E-Mail" />
      <TextField source="notes" label="Notizen" />
    </Datagrid>
  </List>
);
